import React from "react"
import Manager from "../components/manager";
import LoginControl from "../controls/login";
import LoginTheme from "../configs/theme";
import config from "../configs/config.json";

export default function LoginPage() {
  return (
    <Manager name="login" title="Login">
      <LoginTheme>
        <LoginControl {...config}/>
      </LoginTheme>
    </Manager>
  );
}


