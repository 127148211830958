import React, {Component} from "react";
export default class Dialog extends Component {
  componentDidMount = (e) => {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount = (e) => {
    document.body.classList.remove("modal-open");
  }

  preventDefault = (e) => {
    e.preventDefault();
  }

  render = (e) => {
    let dialogClassName = "modal-dialog modal-lg";
    if (this.props.centered) {
      dialogClassName += " modal-dialog-centered";
    }

    const style = this.props.zIndex ? {
      display: "block",
      backgroundColor: "rgba(29, 98, 199, 0.3)",
      zIndex: this.props.zIndex,
    } : {
      display: "block",
      backgroundColor: "rgba(29, 98, 199, 0.3)",
    };

    return (
      <div className="modal fade show" tabIndex="-1" role="dialog" aria-hidden="true" style={style}>
        <div className={`fadein ${dialogClassName || ""}`} role="document" onClick={(e) => e.stopPropagation()} style={this.props.style}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.title}</h5>
              {(!this.props.disableClose) && (
                <button
                  type="button"
                  className="close"
                  testid="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.onRequestClose}>
                  <span aria-hidden="true">
                    <i className="fal fa-times" />
                  </span>
                </button>
              )}
            </div>
            <div style={this.props.bodyStyle} className={`modal-body ${this.props.bodyClassName || ""}`}>
              {this.props.children}
            </div>
            {(this.props.footer) && (
              <div className="modal-footer">
                {this.props.footer}
              </div>
            )}
          </div>
        </div>
      </div>);
  }
}
