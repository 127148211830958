import React, {useState} from "react";

export default function UnmanagedCheckbox (props) {
  const [previewCheck, setPreviewCheck] = useState(false);
  const {activeIcon, className, checked, onMouseEnter, onMouseLeave, testid, hoverAction = true, showPreviewCheck, onClick, name,...rest} = props;
  const style = {};
  let newClassName = `${className || ""} fal checkbox `;

  if (checked) {
    if (!previewCheck) {
      newClassName += "fa-check-square checked-blue";
    } else {
      newClassName += activeIcon !== undefined ? `${activeIcon} checked-blue` : "fa-check-square checked-blue";
    }
  } else {
    if (previewCheck && hoverAction) {
      newClassName += activeIcon !== undefined ? `${activeIcon} checked-preview` : "fa-check-square checked-preview";
    } else {
      newClassName += "fa-square";
    }
  }
  return (<div className="checkbox">
    <input type="checkbox" name={name} class="sr-only" onChange={onClick} checked={checked} />
    <label htmlFor={name} onClick={onClick}>
      <i
        testid={testid}
        style={style}
        className={newClassName}
      />
      <span class="sr-only">{props.srOnly}</span>
    </label>
  </div>);
}
