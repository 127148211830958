import React, {Component} from "react";
import {withFormsy} from "formsy-react";
import Checkbox from "../checkbox";

class FormsyCheckbox extends Component {
  constructor(props) {
    super(props);
    if (props.defaultValue) {
      props.setValue(props.defaultValue);
    }

    this.state = {showGreyPreviewCheck: props.defaultValue};
  }

  addHoverClass = () => {
    if (this.props.value) {
      this.setState({
        showGreyPreviewCheck: false,
      });
    } else {
      this.setState({
        showGreyPreviewCheck: true,
      });
    }
  }

  removeHoverClass = () => {
    if (this.props.value) {
      this.setState({
        showGreyPreviewCheck: true,
      });
    } else {
      this.setState({
        showGreyPreviewCheck: false,
      });
    }
  }

  handleChangeValue = (checked) => {
    return this.props.setValue(checked);
  }

  render() {
    const {activeIcon, className, style, testid, value, ...rest} = this.props;
    return (
      <Checkbox
        testid={testid}
        activeIcon={activeIcon}
        className={className}
        style={style}
        checked={value}
        onMouseEnter={this.addHoverClass.bind(this)}
        onMouseLeave={this.removeHoverClass.bind(this)}
        showPreviewCheck={this.state.showGreyPreviewCheck}
        onClick={() => this.handleChangeValue(!value)}
      />
    );
  }
}


export default withFormsy(FormsyCheckbox);
