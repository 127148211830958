/* eslint-disable no-script-url */
import React, {useState, useRef, useEffect} from "react";
import Formsy from "formsy-react";
import {Link} from "gatsby";
import {useAsyncSetState} from "use-async-setstate";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Dialog from "../components/bootstrap/dialog";
import {useTranslation} from "react-i18next";
import root from "window-or-global";
import queryString from "query-string";

import FormsyCheckbox from "../components/formsy/checkbox";
import FormsyTextInput from "../components/formsy/text-field";
import fetchin from "../logic/utils/fetch";
import sysConfig from "../configs/system.json";


function processDstUrl(u) {
  try {
    if(!u || u === "x" || u === "") {
      return undefined;
    }
    return root.btoa(u);
  } catch(err) {
    console.log("err", err);
  }
}

export default function LoginControl(props) {
  const {
    headerImage,
    loginMessage,
    enableRegister,
    enableTermsAndConditions,
    loginButtonLabel,
    loginUsernameLabel,
    loginUsernamePlaceholder,
    loginPasswordLabel,
    loginPasswordPlaceholder,
    loginButtonBgColour,
    loginButtonTextColour,
    registerButtonLabel,
    registerButtonBgColour,
    registerButtonTextColour,
    privacy,
    terms,
  } = props;

  let search = ""
  if(root.location) {
    search = (`${root.location.search}` || "").toLowerCase();
    console.log("search", root.location);
  }
  const parseQs = queryString.parse(search);
  console.log("parseQs", parseQs);
  const dst = processDstUrl(parseQs.dst);
  console.log("dst", dst);
  const {disableForm} = props;
  const {t} = useTranslation();
  const authFormEl = useRef(null);
  const [creds, setCreds] = useAsyncSetState({
    username: "",
    password: ""
  });
  const [accepttncs, setAcceptTNCs] = useState(false);
  const [state, setState] = useState({
    showTerms: false,
    showPrivacy: false,
    error: false,
    isSubmitted: false,
    message: "",
    loading: false,
  });

  async function checkIfLoggedInOnHotspot() {
    try {
      const text = await fetchin.get(process.env.HS_LOGINSTATUS);
      console.log("data", text);
      const data = JSON.parse(text);
      if (data) {
        if (data.loggedIn === "yes") {
          root.location = process.env.HS_TARGETURL;
        }
      }
    } catch(err) {
      console.log("unable to talk to the hotspot", err);
    }
  }
  useEffect(() => {
    checkIfLoggedInOnHotspot();
    
  }, []);

  async function setFormError(error, isSubmitted, message = "Invalid username/password") {
    console.log("setFormError", state, {error,
      isSubmitted,
      message,
      loading: false});
    let msg = message;
    if (message === "EINVALID") {
      msg = "Invalid username and/or password";
    }
    return setState(Object.assign({}, state, {
      error,
      isSubmitted,
      message: msg,
      loading: false,
    }));
  }

  async function handleSubmit({username, password}) {
    try {
      await setState({isSubmitted: true, loading: true});
      let track = "ESTARTED";
      const result = await fetchin.post(`${sysConfig.baseUri}rest.api/login/`, {
        userName: username,
        password,
      });
      track = "ECOMMFAILURE";
      const {Response, error} = result;
      const status = Response.status;
      if (status === 503) {
        window.location = "/login/?error=503";
        return false;
      }
      if (status === 401) {
        return setFormError(true, false, error);
      }
      track = "EFORMSUBMIT";
      await setCreds({username, password});
      authFormEl.current.submit();
      return undefined;
    } catch (err) {
      console.log("network request failed: rate limited?", err); // eslint-disable-line
      switch(track) {
        case "ECOMMFAILURE":
          return setFormError(true, false, `Failed to login. Please check your connection settings and try again. If error persists contact support.`);
      }
      return setFormError(true, false, `An unknown error has occurred: ${track}` );
    }
  }

  // console.log("props", props)
  return (
    <>
      {parseQs.verified === "true" && (
        <div className="alert alert-success">
          {"Your account has been verified. Login to gain access."}
        </div>
      )}
      {parseQs.forgot === "true" && (
        <div className="alert alert-success">
          {"Your password has been changed."}<br/>
          {"Please check your email for your new password"}
        </div>
      )}
      {state.showPrivacy && (
        <Dialog
          title={t("resource.register.register.privacyPolicy")}
          onRequestClose={() => {
          return setState({
            showPrivacy: false,
          });
        }}>
          <div style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: privacy}} />
        </Dialog>
      )}
      {state.showTerms && (
        <Dialog
          title={t("resource.site.auth.general.termsAndConditions")}
          onRequestClose={() => {
            return setState({
              showTerms: false,
            });
          }}>
          <div style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: terms}} />
        </Dialog>
      )}
      <form ref={authFormEl} action={sysConfig.postAuthTarget} method="post">
        <input type="hidden" name="username" value={creds.username} />
        <input type="hidden" name="password" value={creds.password} />
        <input type="hidden" name="dst" value={dst || process.env.HS_TARGETURL} />
      </form>
      <Formsy className="frm-login"
        onChange={(currentValues, isChanged) => {
          if(isChanged) {
            if(accepttncs !== currentValues.accepttnc) {
              setAcceptTNCs(currentValues.accepttnc)
            }
          }
        }}
        onValidSubmit={handleSubmit}>
        <Container className="form-spacer no-gutters">
          {(headerImage && headerImage !== "") && (
            <Row>
              <Col>
                <img src={headerImage} />
              </Col>
            </Row>
          )}
          {loginMessage && loginMessage !== "" && (
            <Row key={"loginMessage"}>
              <Col xs="12" className="mx-auto">
                <div className="login-title">{loginMessage}</div>
              </Col>
            </Row>
          )}

          {state.error && (
            <Row>
              <Col>
                <div className="alert alert-danger">
                  {state.message}
                </div>
              </Col>
            </Row>
          )}
          <Row key={"usernamefield"} className="field-row username-field">
            <Col>
              <FormsyTextInput
                label={loginUsernameLabel}
                name="username"
                placeholder={loginUsernamePlaceholder}
                testid="username"
                disabled={state.isSubmitted}
                validationError="A valid user name is required"
                required
              />
            </Col>
          </Row>
          <Row key={"passwordfield"} className="field-row password-field">
            <Col>
              <FormsyTextInput
                label={loginPasswordLabel}
                name="password"
                placeholder={loginPasswordPlaceholder}
                type="password"
                testid="password"
                disabled={state.isSubmitted}
                validationError="This field is required"
                required
              />
            </Col>
          </Row>
          {enableTermsAndConditions && (<Row className="align-items-center accepttncs">
            <Col xs="auto" className="mr-1">
              <FormsyCheckbox name="accepttnc" srOnly={"Accept Terms and Conditions"} required/>
            </Col>
            <Col>
              {t("resource.site.auth.general.iAgreeToTheFull")}
              <a href="javascript:;" onClick={() => setState({showTerms: true})}>{t("resource.register.register.termsOfService")}</a>
              {` ${t("resource.register.register.and")} `}
              <a href="javascript:;" onClick={() => setState({showPrivacy: true})}>{t("resource.register.register.privacyPolicy")}</a>
            </Col>
          </Row>)}
          <Row className="login-button-row">
            {/* <Col xs={6} className="align-items-center d-flex">
              <Link className="register-link" to="/forgot-password">{"Forgot Password"}</Link>
            </Col> */}
            <Col>
              <Button
                style={loginButtonBgColour ? {
                  backgroundColor: loginButtonBgColour,
                } : null}
                type={disableForm ? "button" : "submit"}
                loading={state.loading}
                disabled={state.isSubmitted || (enableTermsAndConditions ? !accepttncs : false) }
                colour="primary"
                className="btn-submit btn-login"
                testid="login">
                {loginButtonLabel || "Login"}
              </Button>
            </Col>
          </Row>
          {/* <Row className="divider mb-4">
            <Col xs={5}>
              <div className="line" />
            </Col>
            <Col className="or text-center">
              {"Or"}
            </Col>
            <Col xs={5}>
              <div className="line" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <a href="javascript:;" className="btn connect-with-btn facebook ml-auto mr-2">
                <i className="fab fa-facebook mr-2" />
                {"Facebook"}
              </a>
            </Col>
            <Col xs={12} sm={6}>
              <a href="javascript:;" className="btn connect-with-btn google mr-auto ml-2">
                <i className="fab fa-google mr-2" />
                {"Google"}
              </a>
            </Col>
          </Row> */}
          {enableRegister && (<>
            <hr/>
            <Row className="login-button-row">
              <Col>
                <Link className="register-link" to="/register">{"Register for WiFi Access"}</Link>
              </Col>
            </Row>
          </>)}
        </Container>
      </Formsy>
    </>
  );
}
